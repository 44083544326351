import Vue from 'vue'

// axios
import axios from 'axios'
import mainBaseUrl from "@/global-components";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: `${mainBaseUrl}api/`,
  // timeout: 10000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
