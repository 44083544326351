export default [
  {
    path: "/pages/error/error-404",
    name: "error-404",
    component: () => import("@/views/pages/error/Error404.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/pages/authentication/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/pages/miscellaneous/not-authorized",
    name: "misc-not-authorized",
    component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/pages/miscellaneous/error",
    name: "misc-error",
    component: () => import("@/views/pages/miscellaneous/Error.vue"),
    meta: {
      layout: "full",
    },
  },

  //  Blog
  {
    path: "/pages/posts/list",
    name: "pages-posts-list",
    component: () => import("@/views/pages/blog/BlogList.vue"),
    // meta: {
    //   resource: "Blogger",
    //   action: "Blogger",
    // },
  },
  {
    path: "/pages/posts/categories/list",
    name: "pages-posts-category",
    component: () =>
      import("@/views/pages/blog/categories/categories-list.vue"),
    // meta: {
    //   resource: "Blogger",
    //   action: "Blogger",
    // },
  },
  {
    path: "/pages/posts/categories/:id",
    name: "pages-posts-categories-edit",
    component: () =>
      import("@/views/pages/blog/categories/categories-edit.vue"),
    // meta: {
    //   resource: "Blogger",
    //   action: "Blogger",
    // },
  },
  {
    path: "/pages/blog/comments",
    name: "pages-blog-comments",
    component: () => import("@/views/pages/blog/comments/BlogComments.vue"),
    meta: {
      resource: "Blogger",
      action: "Blogger",
    },
  },
  {
    path: "/pages/blog/comments/:id",
    name: "pages-blog-comments-edit",
    component: () => import("@/views/pages/blog/comments/BlogCommentEdit.vue"),
    meta: {
      resource: "Blogger",
      action: "Blogger",
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/pages/posts/create/:langId?/:mainId?",
    name: "pages-posts-create",
    component: () => import("@/views/pages/blog/BlogCreate.vue"),
  },
  {
    path: "/pages/blog/:id",
    name: "pages-blog-edit",
    component: () => import("@/views/pages/blog/BlogEdit.vue"),
    meta: {
      resource: "Blogger",
      action: "Blogger",
    },
  },

  // Language
  {
    path: "/pages/languages/create",
    name: "pages-languages-create",
    component: () => import("@/views/pages/languages/CreateNewLanguage.vue"),
  },
  {
    path: "/pages/languages",
    name: "pages-languages-list",
    component: () => import("@/views/pages/languages/GetAllLanguages.vue"),
  },

  // Library
  {
    path: "/pages/library",
    name: "pages-library-list",
    component: () => import("@/views/pages/library/LibraryList.vue"),
  },
  {
    path: "/pages/library/add",
    name: "pages-library-add",
    component: () => import("@/views/pages/library/AddItemToLibrary.vue"),
  },

  // Pages
  {
    path: "/pages/pages/create/:langId?/:mainId?",
    name: "pages-create-page",
    component: () => import("@/views/pages/pages/CreateNewPage.vue"),
  },
  {
    path: "/pages/pages",
    name: "pages-all-pages",
    component: () => import("@/views/pages/pages/GetAllPages.vue"),
  },
  {
    path: "/pages/pages/:id",
    name: "pages-edit-page",
    component: () => import("@/views/pages/pages/UpdateAPage.vue"),
  },

  // Comments
  {
    path: "/pages/comments",
    name: "pages-comments-list",
    component: () => import("@/views/pages/comments/CommentsList.vue"),
  },

  // Forms
  {
    path: "/pages/forms/create",
    name: "pages-forms-create",
    component: () => import("@/views/pages/forms/CreateNewForm.vue"),
  },
  {
    path: "/pages/forms/inbox",
    name: "pages-forms-inbox",
    component: () => import("@/views/pages/forms/Inbox/FormInbox.vue"),
  },
  {
    path: "/pages/forms/inbox/:formId",
    name: "pages-forms-single",
    component: () => import("@/views/pages/forms/Inbox/GetASingleForm.vue"),
  },
  {
    path: "/pages/forms",
    name: "pages-forms-list",
    component: () => import("@/views/pages/forms/GetAllForms.vue"),
  },
  {
    path: "/pages/forms/:id",
    name: "pages-forms-update",
    component: () => import("@/views/pages/forms/UpdateAForm.vue"),
  },

  // Menu
  {
    path: "/pages/menu",
    name: "pages-menu-list",
    component: () => import("@/views/pages/menu/GetAllMenu.vue"),
  },
  {
    path: "/pages/menu/create/:langId?/:mainId?",
    name: "pages-menu-create",
    component: () => import("@/views/pages/menu/CreateNewMenu.vue"),
  },

  // Seo
  {
    path: "/pages/seo/pages",
    name: "pages-seo-pages",
    component: () => import("@/views/pages/seo/pages/GetAllPages"),
  },
  {
    path: "/pages/seo/pages/:id",
    name: "pages-seo-pages-single",
    component: () => import("@/views/pages/seo/pages/SinglePageSeo.vue"),
  },
  {
    path: "/pages/seo/blogs",
    name: "pages-seo-blogs",
    component: () => import("@/views/pages/seo/blogs/GetAllBlogs.vue"),
  },
  {
    path: "/pages/seo/blogs/:id",
    name: "pages-seo-blogs-single",
    component: () => import("@/views/pages/seo/blogs/SingleBlogSeo.vue"),
  },
  {
    path: "/pages/seo/blog-categories",
    name: "pages-seo-blog-categories",
    component: () =>
      import("@/views/pages/seo/blogCategory/GetAllBlogCategories.vue"),
  },
  {
    path: "/pages/seo/blog-categories/:id",
    name: "pages-seo-blog-categories-single",
    component: () =>
      import("@/views/pages/seo/blogCategory/SingleBlogCategorySeo.vue"),
  },

  // Setting
  {
    path: "/pages/settings/slider",
    name: "pages-settings-slider",
    component: () => import("@/views/pages/settings/SliderSetting.vue"),
  },
  {
    path: "/pages/settings/robots",
    name: "pages-settings-robots",
    component: () => import("@/views/pages/settings/RobotsSetting.vue"),
  },
  {
    path: "/pages/settings/sitemap",
    name: "pages-settings-sitemap",
    component: () => import("@/views/pages/settings/SitemapSetting.vue"),
  },
  {
    path: "/pages/settings/general",
    name: "pages-settings-general",
    component: () => import("@/views/pages/settings/GeneralSetting.vue"),
  },
  {
    path: "/pages/settings/awards",
    name: "pages-settings-awards",
    component: () => import("@/views/pages/settings/AwardsSetting.vue"),
  },
  {
    path: "/pages/settings/docs",
    name: "pages-settings-docs",
    component: () => import("@/views/pages/settings/DocumentsSetting.vue"),
  },
  {
    path: "/pages/settings/videos",
    name: "pages-settings-videos",
    component: () => import("@/views/pages/settings/VideoSetting.vue"),
  },

  // Email Settings
  {
    path: "/pages/email-settings/",
    name: "pages-email-settings-list",
    component: () => import("@/views/pages/emails/GetAllEmails.vue"),
  },

  // Representative
  {
    path: "/pages/representatives/create",
    name: "pages-representative-create",
    component: () =>
      import("@/views/pages/representative/CreateRepresentative.vue"),
  },
  {
    path: "/pages/representatives/",
    name: "pages-representative-list",
    component: () =>
      import("@/views/pages/representative/GetAllRepresentatives.vue"),
  },
];
