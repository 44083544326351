import Helper from '@/libs/Helper'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import useJwt from '@/auth/jwt/useJwt'

let hub = null
const hubInstance = {
    createNewHubInstance: function () {
        try {
            if (!hub) {
                hub = new HubConnectionBuilder()
                    .configureLogging(LogLevel.Information)
                    .withUrl(`${Helper.baseUrl}hub/mediaHub`, {
                        accessTokenFactory: function () {
                            return `${useJwt.getToken()}`
                        },
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            // 'Token': useJwt.getToken() ? useJwt.getToken() : ''
                        },
                    })
                    .withAutomaticReconnect([0, 0, 5000, 10000])
                    .build()
            }
            this.fillOnMyConnectedArray(hub);
            return hub
        }
        catch (e) {
            console.log(e)
        }
    },
    fillOnMyConnectedArray: function (hub) {
        if (!hub.onMyConnected) {
            hub.onMyConnected = [];
        }
    },
    executeArrMyInit: function(hub) {
        if (hub && hub.onMyConnected && hub.onMyConnected.length > 0) {
            for(let i = 0; i < hub.onMyConnected.length;i++)
                hub.onMyConnected[i]();
            hub.onMyConnected = [];
        }
    },
    connectToServer: async function (successCallBack) {
        try {
            await hub.start();
            this.executeArrMyInit(hub);
            return successCallBack(hub)
        }
        catch (e) {
            console.log(e)
            throw e
        }
    },
    isConnected: function() {
        return hub != null && hub.state === 'Connected';
    },
    getInstance: async function () {
        if (hub.state === 'Connected') {
            hub.on('Exception', function(res) {
                if(res.code === 401){
                    console.log('Its unAuthorized')
                    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
                    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

                    // Remove userData from localStorage
                    localStorage.removeItem('userData')
                    location.href = '/login';
                }
            })
            return hub
        } else {
            await this.connectToServer()
        }
    },

    getInstanceState: function () {
        return hub.state
    },

    closeInstance: async function () {
        if (hub) {
            hub.disconnected()
        }
    }
}

export { hubInstance }
